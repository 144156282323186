import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, reaction } from 'mobx';

import { PeriodLabel } from 'src/constants';

class DatePickerStore {
  checkedPeriod: string = '';
  date: string = '';
  customDate: string = '';

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.checkedPeriod,
      (period) => this.updateDateByPeriod(period)
    );

    this.humanFormatDate = this.humanFormatDate.bind(this);
    this.setCheckedPeriod = this.setCheckedPeriod.bind(this);
  }

  get todayDate() {
    return dayjs().hour(0).minute(0).second(0).millisecond(0).toISOString();
  }

  get tomorrowDate() {
    return dayjs().add(1, 'day').hour(0).minute(0).second(0).millisecond(0).toISOString();
  }

  get afterTomorrowDate() {
    return dayjs().add(2, 'day').hour(0).minute(0).second(0).millisecond(0).toISOString();
  }

  get isCustomDateInputDisabled() {
    return this.checkedPeriod !== PeriodLabel.OTHER_DAY;
  }

  getPeriod(date: string) {
    if (this.isToday(date)) {
      return PeriodLabel.TODAY;
    }
    if (this.isTomorrow(date)) {
      return PeriodLabel.TOMORROW;
    }
    if (this.isDayAfterTomorrow(date)) {
      return PeriodLabel.DAY_AFTER_TOMORROW;
    }
    return PeriodLabel.OTHER_DAY;
  }

  dotFormatDate(date: string | Dayjs) {
    return dayjs(date).format('DD.MM.YYYY');
  }

  humanFormatDate(date: string | Dayjs) {
    return dayjs(date).format('DD MMMM YYYY');
  }

  getCustomDateInputProps() {
    return {
      value: this.customDate ?? '',
      onChange: (value: string) => this.setCustomDate(value),
    };
  }

  getCustomDateValidationProps() {
    const isValid = this.isCustomDateValid();
    const textError = isValid ? '' : 'Неверно указана дата';

    return { isValid, textError };
  }

  isCustomDateValid() {
    return dayjs(this.customDate, 'DD.MM.YYYY', true).isValid() || this.customDate === '';
  }

  isToday(date: string | Dayjs) {
    return dayjs(this.todayDate).diff(date, 'day') === 0;
  }

  isTomorrow(date: string | Dayjs) {
    return dayjs(date).diff(this.todayDate, 'day') === 1;
  }

  isDayAfterTomorrow(date: string | Dayjs) {
    return dayjs(date).diff(this.todayDate, 'day') === 2;
  }

  setCheckedPeriod(period: string) {
    this.checkedPeriod = period;
  }

  setCustomDate(value: string) {
    this.customDate = value;

    if (value && this.isCustomDateValid()) {
      this.date = dayjs(this.customDate, 'DD.MM.YYYY').toISOString();
    }
  }

  setFilterValues(date: string) {
    this.checkedPeriod = this.getPeriod(date);
    if (this.checkedPeriod === PeriodLabel.OTHER_DAY) {
      this.customDate = this.dotFormatDate(date);
      this.date = date;
    } else {
      this.customDate = '';
      this.updateDateByPeriod(this.checkedPeriod);
    }
  }

  updateDateByPeriod(period: string) {
    switch (period) {
      case PeriodLabel.TODAY:
        this.date = this.todayDate;
        return;
      case PeriodLabel.TOMORROW:
        this.date = this.tomorrowDate;
        return;
      case PeriodLabel.DAY_AFTER_TOMORROW:
        this.date = this.afterTomorrowDate;
        return;
      default:
        return;
    }
  }
}

export default DatePickerStore;
