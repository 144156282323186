import { getMask } from './get-mask';

export const getPhoneMask = getMask((value) => (str, index) => {
  const regexp = /\d/;
  let isTextBlack = /[\d+]/.test(str);

  if ((str === '(' || str === '-') && regexp.test(value[index + 1])) {
    isTextBlack = true;
  }

  if ((str === ')' || str === '-') && regexp.test(value[index - 1])) {
    isTextBlack = true;
  }

  return {
    isTextBlack,
    str,
  };
});
