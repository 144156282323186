import { makeObservable, action } from 'mobx';

class BasePopupStore {
  [propertyName: string]: any;

  constructor() {
    makeObservable(this, {
      setPopupState: action,
      updatePopupState: action,
      hidePopup: action,
      showPopup: action,
    });
  }

  setPopupState(popupName: string, state: any) {
    this[this.getPopupStatePropName(popupName)] = state;
  }

  updatePopupState(popupName: string, state: any) {
    Object.assign(this.getPopupState(popupName), state);
  }

  hidePopup(popupName: string) {
    this[this.getPopupStatePropName(popupName)] = this.getInitialPopupState();
  }

  showPopup(popupName: string, state = {}) {
    this.setPopupState(popupName, Object.assign({ isVisible: true }, state));
  }

  getPopupState(popupName: string) {
    return this[this.getPopupStatePropName(popupName)];
  }

  getPopupStatePropName(popupName: string) {
    return `${popupName}PopupState`;
  }

  getInitialPopupState() {
    return {
      isVisible: false,
    };
  }
}

export default BasePopupStore;
