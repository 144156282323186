import React from 'react';
import classNames from 'classnames';

import OrderTableHead from '../order-table-head';
import OrderTableRow from '../order-table-row';

import farewellStore from 'src/stores/control-room/farewell-store';

import { OrderTableProps } from './order-table.props';
import { TableStatus } from 'src/constants';

import './order-table.scss';

function OrderTable({ farewellHall }: OrderTableProps) {
  const { isExpanded, isOpen, isShowAlertIcon, ordersCount, preOrdersCount, tableData } =
    farewellStore.filteredListFarewellHalls[farewellHall];

  const [isAnimated, setIsAnimated] = React.useState(false);
  const [style, setStyle] = React.useState({
    height: '',
  });

  const bodyRef: React.MutableRefObject<null | HTMLTableSectionElement> = React.useRef(null);
  const headRef: React.MutableRefObject<null | HTMLTableSectionElement> = React.useRef(null);
  const cellWrapperRef: React.MutableRefObject<null | HTMLTableCellElement> = React.useRef(null);

  const handleClick = () => {
    if (isAnimated) {
      return;
    }

    farewellStore.onTableChange(farewellHall, TableStatus.IS_OPEN, !isOpen);

    const height = `${cellWrapperRef.current?.clientHeight ?? 0}px`;

    setStyle({ height });
  };

  React.useEffect(() => {
    const cellWrapperEl = cellWrapperRef.current;

    const handleTransitionEnd = (e: any) => {
      if (isAnimated && e?.target?.tagName === 'TD' && e?.propertyName === 'height') {
        setStyle(() => ({ height: '' }));
        setIsAnimated(false);
        farewellStore.onTableChange(farewellHall, TableStatus.IS_EXPANDED, !isExpanded);
      }

      if (cellWrapperEl && !isAnimated) {
        cellWrapperEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };

    if (cellWrapperEl && isAnimated) {
      cellWrapperEl.addEventListener('transitionend', handleTransitionEnd);
    }

    if (!isAnimated && style.height) {
      const height = isExpanded
        ? `${headRef.current?.clientHeight ?? 0}px`
        : `${(headRef.current?.clientHeight ?? 0) + (bodyRef.current?.scrollHeight ?? 0)}px`;
      setStyle({ height });
      setIsAnimated(true);
    }

    return () => {
      if (cellWrapperEl) {
        cellWrapperEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [isAnimated, isExpanded, farewellHall, style]);

  const tableClasses = classNames(
    'table order-table_table',
    isOpen && 'order-table_table_opened',
    isExpanded && 'order-table_table_expanded'
  );

  const cellWrapperClasses = classNames(
    'order-table__wrapper',
    isOpen && 'order-table__wrapper_expanded'
  );

  return (
    <tr className="table__row order-table">
      <td className={cellWrapperClasses} ref={cellWrapperRef} colSpan={6} style={style}>
        <table className={tableClasses}>
          <OrderTableHead
            isShowAlertIcon={isShowAlertIcon}
            ordersCount={ordersCount}
            preOrdersCount={preOrdersCount}
            onClick={handleClick}
            ref={headRef}
            title={farewellHall}
          />

          <tbody className="order-table__body" ref={bodyRef}>
            {tableData.map((item, index, arr) => (
              <OrderTableRow
                {...item}
                isTableExpanded={isExpanded}
                isLast={index === arr.length - 1}
                key={item.id}
              />
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
}

export default OrderTable;
