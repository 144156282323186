import { makeAutoObservable, reaction } from 'mobx';
import dayjs from 'dayjs';

import farewellStore from './farewell-store';

import { doPost } from 'src/api';
import { DATE_FORMAT_API, REQUEST_DELAY } from 'src/constants';
import { getFormattedDate } from 'src/utils';

class FiltersStore {
  date: string = dayjs().add(1, 'day').toISOString();
  isUrgent: boolean = false;
  searchStr: string = '';
  selectedHallsIds: string[] = [];

  constructor() {
    makeAutoObservable(this);

    this.changeSearchStr = this.changeSearchStr.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setSelectedHallsIds = this.setSelectedHallsIds.bind(this);
    this.toggleIsUrgent = this.toggleIsUrgent.bind(this);

    reaction(
      () => this.date,
      () => farewellStore.setIsLoading(true)
    );

    reaction(
      () => this.date,
      (date) => this.changeDate(date),
      { delay: REQUEST_DELAY }
    );
  }

  changeSearchStr(newSearchStr: string) {
    this.searchStr = newSearchStr;
  }

  setDate(date: string) {
    this.date = dayjs(date).toISOString();
  }

  setNextDate() {
    this.date = dayjs(this.date).add(1, 'day').toISOString();
  }

  setPreviousDate() {
    this.date = dayjs(this.date).add(-1, 'day').toISOString();
  }

  setSelectedHallsIds(ids: string[]) {
    this.selectedHallsIds = ids;
  }

  setIsUrgent(isUrgent: boolean) {
    this.isUrgent = isUrgent;
  }

  toggleIsUrgent() {
    this.setIsUrgent(!this.isUrgent);
  }

  clearFilters() {
    this.changeSearchStr('');
    this.setIsUrgent(false);
  }

  async changeDate(date: string) {
    this.clearFilters();

    try {
      await doPost('/eventdate', {
        name: 'farewells',
        date: getFormattedDate(date, DATE_FORMAT_API),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async initDate() {
    await this.changeDate(this.date);
  }
}

export default new FiltersStore();
