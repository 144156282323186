import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

import { ArrowButtonProps } from './arrow-button.props';

import './arrow-button.scss';

function ArrowButton({ ariaLabel, className, direction, isDisabled, onClick }: ArrowButtonProps) {
  return (
    <button
      className={classNames(
        `arrow-button arrow-button_${direction}`,
        className,
        isDisabled && 'arrow-button_disabled'
      )}
      aria-label={ariaLabel}
      disabled={isDisabled}
      onClick={onClick}
      type="button"
    >
      <ArrowIcon className="arrow-button__icon" />
    </button>
  );
}

export default ArrowButton;
