import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import globalAlertStore from 'src/stores/global-alert-store';
import globalAppStore from 'src/stores/global-app-store';
import filtersStore from './filters-store';

import { IInfoCrematoriumDay } from 'src/interfaces/info-crematorium-day.interface';
import { IWorkDayApi } from 'src/interfaces/work-day-api.interface';
import { IWorkerApi } from 'src/interfaces/worker-api.interface';
import { SelectOption } from 'src/interfaces/select-option.interface';
import { downloadFile, getFormattedDate } from 'src/utils';
import { doGet, doPost } from 'src/api';
import { AlertType } from 'src/components/ui/alert';
import { DATE_FORMAT_API } from 'src/constants';

class WorkDayStore {
  date: string | null = null;
  dayId: string | null = null;
  listCrematoriums: IInfoCrematoriumDay[] = [];
  listWorkers: SelectOption[] = [];

  constructor() {
    makeAutoObservable(this);

    this.getExport = this.getExport.bind(this);
  }

  setListWorkers(listWorkers: IWorkerApi[]) {
    this.listWorkers = getWorkersFromApi(listWorkers);
  }

  setDayId(dayId: string | null) {
    this.dayId = dayId;
  }

  setDate(date: string | null) {
    this.date = date;
  }

  setWorkDay({ id, crematoriums = [], date }: IWorkDayApi) {
    const listCrematoriums = crematoriums.map(({ id, workers }) => {
      const name =
        globalAppStore.crematoriums.find((crematorium) => crematorium.id === id)?.name ?? '';

      return { id, name, workers: getWorkersFromApi(workers) };
    });

    this.setDate(date);
    this.setDayId(id);
    this.setListCrematoriums(listCrematoriums);
  }

  setListCrematoriums(listCrematoriums: IInfoCrematoriumDay[]) {
    this.listCrematoriums = listCrematoriums;
  }

  async addWorkers(crematoriumId: string, listSelectedWorkersIdx: string[]) {
    globalAppStore.setIsLoading(true);

    try {
      const data = {
        crematorium: crematoriumId,
        workers: listSelectedWorkersIdx,
      };

      await doPost(`/days/${this.dayId}/workers`, data);
    } catch (e) {
      console.log('e', e);
    }

    globalAppStore.setIsLoading(false);
  }

  async getExport(crematoriumId: string, name: string) {
    try {
      const date = filtersStore.date;
      const response = await doGet(
        `/acts?date=${getFormattedDate(date, DATE_FORMAT_API)}&crematorium=${crematoriumId}`,
        {
          responseType: 'blob',
        }
      );

      if (response.data?.size > 0) {
        const fileName = `${name.split(' ').join('_')}_${getFormattedDate(
          date ?? '',
          'YYYY_MM_DD'
        )}`;

        downloadFile(response.data, fileName);
      } else {
        globalAlertStore.addAlert({
          id: uuidv4(),
          desc: '',
          timeout: 10000,
          title: 'Нет данных для экспорта',
          type: AlertType.error,
        });
      }
    } catch (e) {
      console.log('e', e);
    }
  }
}

function getWorkersFromApi(workers: IWorkerApi[]) {
  return workers
    .map(({ id, short_name }) => ({ id, value: short_name }))
    .sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      }
      return -1;
    });
}

export default new WorkDayStore();
