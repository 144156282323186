import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Checkbox from 'src/components/ui/checkbox';
import MultiSelect from 'src/components/ui/multi-select';

import farewellStore from 'src/stores/control-room/farewell-store';
import filtersStore from 'src/stores/control-room/filters-store';

import { FarewellsFiltersProps } from './farewells-filters.props';
import { isEqualItems } from 'src/utils';
import { SelectOption } from 'src/interfaces/select-option.interface';

import './farewells-filters.scss';

const FarewellsFilters = observer(({ className }: FarewellsFiltersProps) => {
  const items = farewellStore.multiSelectItems;
  const date = filtersStore.date;
  const previousItems = React.useRef<SelectOption[]>([]);
  const previousDate = React.useRef<string>('');

  React.useEffect(() => {
    if (!isEqualItems(previousItems.current, items)) {
      const unSelectedHallsIds = previousItems.current
        .filter((hall) => !filtersStore.selectedHallsIds.includes(hall.id))
        .map((hall) => hall.id);

      const newSelectedHallsIds = items
        .filter((hall) => !unSelectedHallsIds.includes(hall.id))
        .map((hall) => hall.id);

      filtersStore.setSelectedHallsIds(newSelectedHallsIds);
      previousItems.current = items;
      previousDate.current = date;
      return;
    }

    if (previousDate.current !== date) {
      filtersStore.setSelectedHallsIds(items.map((hall) => hall.id));
      previousDate.current = date;
    }
  }, [items, date]);

  return (
    <div className={classNames('farewells-filters', className)}>
      <MultiSelect
        className="farewells-filters__select"
        items={items}
        label="Зал прощания"
        value={filtersStore.selectedHallsIds}
        onChange={filtersStore.setSelectedHallsIds}
      />
      <Checkbox
        id="checkboxFarewellsUrgent"
        isChecked={filtersStore.isUrgent}
        label="Только срочные"
        onChange={filtersStore.toggleIsUrgent}
      />
    </div>
  );
});

export default FarewellsFilters;
