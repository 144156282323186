import { makeAutoObservable, reaction } from 'mobx';

import filtersStore from './filters-store';

import { getLocalStorageItem, setLocalStorageItem } from 'src/helpers/localStorageHelper';
import { IFarewell } from 'src/interfaces/farewell.interface';
import { IFarewellApi } from 'src/interfaces/farewell-api.interface';
import { IPersonApi } from 'src/interfaces/person-api.interface';
import { FarewellType, TableStatus, APP_LOCALSTORAGE_LIST_VIEWED_ORDERS_KEY } from 'src/constants';
import {
  getFullName,
  getParsedDate,
  isBeforeCurrentDate,
  isBeforeISODate,
  mapApiToDeceased,
} from 'src/utils';
import { IListFarewellHalls } from 'src/interfaces/list-farewell-halls.interface';

interface IViewedOrderId {
  id: string;
  date: string;
}

class FarewellStore {
  farewells: IFarewell[] = [];
  isLoading: boolean = false;
  listFarewellHalls: IListFarewellHalls = {};
  listViewedOrdersId: IViewedOrderId[] = [];
  mapSelectedHall: string = '';
  totalOrdersCount = 0;
  totalPreOrdersCount = 0;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.farewells,
      (data) => this.updateListFarewellHalls(data)
    );

    reaction(
      () => filtersStore?.date,
      () => this.closeTables()
    );

    this.onTableChange = this.onTableChange.bind(this);
    this.onTableExpanded = this.onTableExpanded.bind(this);

    this.listViewedOrdersId = JSON.parse(
      getLocalStorageItem(APP_LOCALSTORAGE_LIST_VIEWED_ORDERS_KEY) ?? '[]'
    );
    this.listViewedOrdersId = this.listViewedOrdersId.filter(
      (item) => !isBeforeCurrentDate(item.date)
    );
    setLocalStorageItem(
      APP_LOCALSTORAGE_LIST_VIEWED_ORDERS_KEY,
      JSON.stringify(this.listViewedOrdersId)
    );
  }

  setFarewells(farewells: IFarewell[]) {
    this.farewells = farewells ?? [];
  }

  setApiFarewells(farewells: IFarewellApi[]) {
    this.setIsLoading(false);
    this.setFarewells(mapApiToFarewell(farewells ?? []));
  }

  setListFarewellHalls(listFarewellHalls: IListFarewellHalls) {
    this.listFarewellHalls = listFarewellHalls;
  }

  setMapSelectedHall(hallName: string) {
    this.mapSelectedHall = hallName;
  }

  setTotalOrdersCount(totalOrdersCount: number) {
    this.totalOrdersCount = totalOrdersCount;
  }

  setTotalPreOrdersCount(totalPreOrdersCount: number) {
    this.totalPreOrdersCount = totalPreOrdersCount;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  get filteredListFarewellHalls() {
    let listFarewellHalls = { ...this.listFarewellHalls };

    const selectedListFarewellHalls: any = {};
    Object.keys(listFarewellHalls).forEach((farewellHall) => {
      if (filtersStore.selectedHallsIds.includes(listFarewellHalls[farewellHall].hallId)) {
        selectedListFarewellHalls[farewellHall] = listFarewellHalls[farewellHall];
      }
    });
    listFarewellHalls = selectedListFarewellHalls;

    if (filtersStore.isUrgent) {
      const urgentListFarewellHalls: any = {};

      Object.keys(listFarewellHalls).forEach((farewellHall) => {
        const filteredFarewellHall = { ...listFarewellHalls[farewellHall] };
        filteredFarewellHall.tableData = filteredFarewellHall.tableData.filter((row) => row.urgent);

        if (filteredFarewellHall.tableData.length > 0) {
          urgentListFarewellHalls[farewellHall] = filteredFarewellHall;
        }
      });

      listFarewellHalls = urgentListFarewellHalls;
    }

    if (filtersStore.searchStr) {
      const searchedListFarewellHalls: any = {};

      Object.keys(listFarewellHalls).forEach((farewellHall) => {
        const filteredFarewellHall = { ...listFarewellHalls[farewellHall] };

        filteredFarewellHall.tableData = filteredFarewellHall.tableData.filter(({ deceased }) =>
          getFullName(deceased).toLowerCase().includes(filtersStore.searchStr.toLowerCase())
        );

        if (filteredFarewellHall.tableData.length > 0) {
          searchedListFarewellHalls[farewellHall] = filteredFarewellHall;
        }
      });

      listFarewellHalls = searchedListFarewellHalls;
    }

    return listFarewellHalls;
  }

  get multiSelectItems() {
    return (
      Object.keys(this.listFarewellHalls)
        .map((farewellHall) => {
          return {
            id: this.listFarewellHalls[farewellHall].hallId,
            value: farewellHall,
          };
        })
        .sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          return -1;
        }) ?? []
    );
  }

  updateListFarewellHalls(farewells: IFarewell[]) {
    const newListFarewellHalls: any = {};
    let totalOrders = 0;
    let totalPreOrders = 0;

    farewells.forEach((item) => {
      const hall = item.hall.name;
      const hallId = item.hall.id;

      if (!newListFarewellHalls[hall]) {
        newListFarewellHalls[hall] = {};
        newListFarewellHalls[hall].tableData = [];
        newListFarewellHalls[hall].hallId = hallId;
      }

      if (
        !isBeforeISODate(item.date) &&
        !item.new &&
        !this.listViewedOrdersId.find((i) => i.id === item.id)
      ) {
        item.new = true;
      }

      if (item.new && this.listViewedOrdersId.find((i) => i.id === item.id)) {
        item.new = false;
      }

      newListFarewellHalls[hall].tableData.push(item);
    });

    Object.keys(newListFarewellHalls).forEach((farewellHall) => {
      newListFarewellHalls[farewellHall].isOpen =
        (this.listFarewellHalls[farewellHall]?.isOpen || this.mapSelectedHall === farewellHall) ??
        false;

      newListFarewellHalls[farewellHall].isExpanded =
        (this.listFarewellHalls[farewellHall]?.isExpanded ||
          this.mapSelectedHall === farewellHall) ??
        false;

      const { sortedRows, ordersCount, preOrdersCount, isShowAlertIcon } = sortRows(
        newListFarewellHalls[farewellHall].tableData
      );

      totalOrders += ordersCount;
      totalPreOrders += preOrdersCount;

      newListFarewellHalls[farewellHall].isShowAlertIcon = isShowAlertIcon;
      newListFarewellHalls[farewellHall].ordersCount = ordersCount;
      newListFarewellHalls[farewellHall].preOrdersCount = preOrdersCount;
      newListFarewellHalls[farewellHall].tableData = sortedRows;
    });

    this.setListFarewellHalls(newListFarewellHalls);
    this.setTotalOrdersCount(totalOrders);
    this.setTotalPreOrdersCount(totalPreOrders);
  }

  onTableChange(hall: string, tableStatus: TableStatus, value: boolean) {
    const newListFarewellHalls = { ...this.listFarewellHalls };
    const updatedHall = { ...newListFarewellHalls[hall] };
    updatedHall[tableStatus] = value;
    newListFarewellHalls[hall] = updatedHall;
    this.setListFarewellHalls(newListFarewellHalls);
  }

  onTableExpanded(id: string) {
    let newData = [...this.farewells];

    const index = newData.findIndex((item) => item.id === id);
    if (index > -1) {
      const newFarewell = { ...newData[index] };
      newFarewell.new = false;

      newData = [...newData.slice(0, index), newFarewell, ...newData.slice(index + 1)];
      this.setFarewells(newData);

      this.listViewedOrdersId.push({
        id,
        date: getParsedDate(newFarewell.date),
      });

      setLocalStorageItem(
        APP_LOCALSTORAGE_LIST_VIEWED_ORDERS_KEY,
        JSON.stringify(this.listViewedOrdersId)
      );
    }
  }

  closeTables() {
    const newListFarewellHalls = { ...this.listFarewellHalls };

    Object.keys(newListFarewellHalls).forEach((farewellHall) => {
      const updatedHall = { ...newListFarewellHalls[farewellHall] };
      updatedHall.isExpanded = false;
      updatedHall.isOpen = false;
      newListFarewellHalls[farewellHall] = updatedHall;
    });

    this.setListFarewellHalls(newListFarewellHalls);
    this.setMapSelectedHall('');
  }
}

function mapApiToFarewell(items: IFarewellApi[]) {
  return items.map(({ agent, deceased, ...rest }) => ({
    agent: mapApiToAgent(agent),
    deceased: mapApiToDeceased(deceased),
    ...rest,
  }));
}

function mapApiToAgent({
  firstname: firstName,
  lastname: lastName,
  patronymic,
  phone,
}: IPersonApi) {
  return { firstName, lastName, patronymic, phone };
}

const sortRows = (data: IFarewell[]) => {
  const canceledOrderRows: IFarewell[] = [];
  const orderRows: IFarewell[] = [];
  const preOrderRows: IFarewell[] = [];

  let isShowAlertIcon = false;

  data.forEach((item) => {
    if (item.new) {
      isShowAlertIcon = true;
    }

    if (item.canceled) {
      canceledOrderRows.push(item);
      return;
    }

    if (item.type === FarewellType.ORDER) {
      orderRows.push(item);
      return;
    }

    if (item.type === FarewellType.PRE_ORDER) {
      preOrderRows.push(item);
    }
  });

  const sortedRows = [...orderRows, ...preOrderRows, ...canceledOrderRows];
  const ordersCount = orderRows.length;
  const preOrdersCount = preOrderRows.length;

  return { sortedRows, ordersCount, preOrdersCount, isShowAlertIcon };
};

export default new FarewellStore();
