import React from 'react';

import { MapModalProps } from './map-modal.props';

import './map-modal.scss';

const MapModal = ({ halls, onClick }: MapModalProps) => (
  <div className="map-modal">
    {halls.map((hall) => (
      <p className="map-modal__text" onClick={() => onClick(hall)} key={hall.id}>
        <span className="map-modal__text-part">{hall.name}</span>
        <span className="map-modal__text-part map-modal__text-part_orders">
          {hall.ordersCount}/{hall.preOrdersCount}
        </span>
      </p>
    ))}
  </div>
);

export default MapModal;
