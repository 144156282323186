import React from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import MapModal from './components';

import farewellStore from 'src/stores/control-room/farewell-store';
import mapStore from 'src/stores/map-store';

import useWindowSize from 'src/hooks/use-window-size';
import { AppRoute, DEFAULT_ZOOM, MAP_MODAL_X_OFFSET, MARKER_SIZE } from 'src/constants';
import { IMarker } from 'src/interfaces/marker.interface';
import { IHall } from 'src/interfaces/hall.interface';

const HEADER_DESKTOP_HEIGHT = 64;

const libraries: 'geometry'[] = ['geometry'];

const markerIcon = {
  url: `data:image/svg+xml,%3Csvg width='${MARKER_SIZE}' height='${MARKER_SIZE}' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='4.5' fill='%23FF6B00' stroke='%23FAFAFA'/%3E%3C/svg%3E%0A`,
};

const mapOptions = {
  mapTypeControl: false,
  mapTypeId: 'roadmap',
  streetViewControl: false,
};

const getPixelPositionOffset = (offsetWidth: number, offsetHeight: number) => {
  return {
    x: MAP_MODAL_X_OFFSET,
    y: -(offsetHeight / 2 + MARKER_SIZE / 2),
  };
};

const Map = observer(() => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: (window as any).GOOGLE_MAP_API_KEY,
    language: 'ru',
    libraries,
  });
  const history = useHistory();
  const windowSize = useWindowSize();
  const height = windowSize.height && `${windowSize.height - HEADER_DESKTOP_HEIGHT}px`;

  const handleMarkerClick = (hall: IHall) => {
    farewellStore.setMapSelectedHall(hall.name);
    history.push(AppRoute.CONTROL_ROOM_PAGE_ROUTE);
  };

  return isLoaded ? (
    <div className="map">
      <GoogleMap
        center={mapStore.mapCenter}
        mapContainerStyle={{ height, width: '100%' }}
        options={mapOptions}
        zoom={DEFAULT_ZOOM}
        onLoad={mapStore.onLoad}
        onUnmount={mapStore.onUnmount}
      >
        {mapStore.markers.map((marker: IMarker) => (
          <React.Fragment key={`${marker.coordinates.lat}${marker.coordinates.lng}`}>
            <Marker icon={markerIcon} position={marker.coordinates} />
            <OverlayView
              getPixelPositionOffset={getPixelPositionOffset}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={marker.coordinates}
            >
              <MapModal halls={marker.halls} onClick={handleMarkerClick} />
            </OverlayView>
          </React.Fragment>
        ))}
      </GoogleMap>
    </div>
  ) : (
    <Loader className="loader_position_overlay" />
  );
});

export default Map;
