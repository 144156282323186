import { IPersonApi } from '../interfaces/person-api.interface';

export function mapApiToDeceased({
  birth_date: birthDate,
  death_date: deathDate,
  firstname: firstName,
  lastname: lastName,
  patronymic,
}: IPersonApi) {
  return { birthDate, deathDate, firstName, lastName, patronymic };
}
