import { makeAutoObservable } from 'mobx';

import { AlertType } from 'src/components/ui/alert';

interface IAlert {
  desc: string;
  id: string;
  timeout?: number;
  title: string;
  type: AlertType;
  withIcon?: boolean;
}

class GlobalAlertStore {
  alertItems: IAlert[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAlerts(alerts: IAlert[]) {
    this.alertItems = alerts;
  }

  get alerts() {
    return this.alertItems;
  }

  addAlert(alert: IAlert) {
    this.setAlerts([...this.alertItems, alert]);
  }

  deleteAlert(id: string) {
    this.setAlerts(this.alertItems.filter((alert) => alert.id !== id));
  }
}

export default new GlobalAlertStore();
