import { useEffect } from 'react';

export default function useKeyUp(
  targetKey: string,
  handler: () => void,
  isListenerStopped: boolean = false
) {
  useEffect(() => {
    const handleKeyUp = (evt: KeyboardEvent) => {
      if (evt.key === targetKey) {
        (evt.target as any).blur();
        handler();
      }
    };

    if (!isListenerStopped) {
      window.addEventListener('keyup', handleKeyUp);
    } else {
      window.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [targetKey, handler, isListenerStopped]);
}
