export enum AlertType {
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export interface IAlertProps {
  desc: string;
  onClose: () => void;
  timeout?: number;
  title: string;
  type: AlertType;
  withIcon?: boolean;
}
