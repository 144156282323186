import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { DatePickerModalProps } from './date-picker-modal.props';
import { AppRoute } from 'src/constants';

import './date-picker-modal.scss';

function DatePickerModal({ className, children }: DatePickerModalProps) {
  const { pathname } = useLocation();

  return (
    <div
      className={classNames(
        'date-picker-modal',
        pathname === AppRoute.MAP_PAGE_ROUTE && 'date-picker-modal_position_right',
        className
      )}
    >
      <div className="date-picker-modal__wrapper">{children}</div>
    </div>
  );
}

export default DatePickerModal;
