import { makeAutoObservable, reaction } from 'mobx';

import farewellStore from './control-room/farewell-store';

import { ICoords } from 'src/interfaces/coords.interface';
import { IHallApi } from 'src/interfaces/hall.interface';
import { IMarker } from 'src/interfaces/marker.interface';
import { DEFAULT_ZOOM } from 'src/constants';

const MOSCOW_COORDINATES = { lat: 55.75115, lng: 37.649118 };

class MapStore {
  halls: IHallApi[] = [];
  map: google.maps.Map | null = null;
  mapCenter: google.maps.LatLng | ICoords = MOSCOW_COORDINATES;
  markers: IMarker[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.markers,
      () => this.updateBounds()
    );

    reaction(
      () => [farewellStore?.listFarewellHalls, this.map],
      () => this.setMarkers()
    );

    this.onLoad = this.onLoad.bind(this);
    this.setHalls = this.setHalls.bind(this);
    this.setMap = this.setMap.bind(this);
    this.onUnmount = this.onUnmount.bind(this);
  }

  get polygonCoords() {
    return this.markers.map((marker: IMarker) => marker?.coordinates);
  }

  getMapCenter() {
    const bounds = new window.google.maps.LatLngBounds();
    let center;
    if (this.polygonCoords.length > 0) {
      this.polygonCoords.forEach((element: ICoords) => {
        if (element) {
          bounds.extend(element);
        }
      });
      center = bounds.getCenter();
    } else {
      center = MOSCOW_COORDINATES;
    }

    this.setMapCenter(center);
  }

  onLoad(loadedMap: google.maps.Map) {
    this.setMap(loadedMap);
  }

  onUnmount() {
    this.setMap(null);
  }

  setHalls(halls: IHallApi[]) {
    this.halls = halls
      .filter((hall: IHallApi) => Boolean(hall.coordinates))
      .map((hall: IHallApi) => ({
        ...hall,
        coordinates: { lat: Number(hall.coordinates.lat), lng: Number(hall.coordinates.lng) },
      }));
  }

  setMap(map: google.maps.Map | null) {
    this.map = map;
  }

  setMapCenter(coords: google.maps.LatLng | ICoords) {
    this.mapCenter = coords;
  }

  setMarkers() {
    if (this.map) {
      const farewellsStoreHalls = farewellStore.listFarewellHalls;
      const farewellsHallsNames = Object.keys(farewellsStoreHalls);
      const newMarkers: IMarker[] = [];
      const getExistingMarker = ({ lat, lng }: ICoords) =>
        newMarkers.find(
          (item: IMarker) => item.coordinates.lat === lat && item.coordinates.lng === lng
        );
      const hallsWithCoordsAndFarewells = this.halls.filter(
        (hall) => Boolean(hall?.coordinates) && farewellsHallsNames.includes(hall?.name)
      );
      hallsWithCoordsAndFarewells.forEach((hall) => {
        const existingMarker = getExistingMarker(hall.coordinates);
        const hallInfo = {
          id: hall.id,
          name: hall.name,
          coordinates: hall.coordinates,
          ordersCount: farewellsStoreHalls[hall.name].ordersCount,
          preOrdersCount: farewellsStoreHalls[hall.name].preOrdersCount,
        };

        if (existingMarker) {
          existingMarker.halls.push(hallInfo);
        } else {
          newMarkers.push({
            coordinates: hall.coordinates,
            halls: [hallInfo],
          });
        }
      });
      this.markers = newMarkers;
    }
  }

  updateBounds() {
    if (this.map && this.markers) {
      this.getMapCenter();
      const bounds = new window.google.maps.LatLngBounds();
      if (this.polygonCoords.length > 1) {
        this.polygonCoords.forEach((element: ICoords) => {
          if (element) {
            bounds.extend(element);
          }
        });
        this.map.fitBounds(bounds);
        const listener = google.maps.event.addListener(this.map, 'bounds_changed', () => {
          const zoom = this.map?.getZoom() || DEFAULT_ZOOM;
          this.map?.setZoom(zoom - 1);
          google.maps.event.removeListener(listener);
        });
      } else {
        this.map?.setZoom(DEFAULT_ZOOM);
      }
    }
  }
}

export default new MapStore();
