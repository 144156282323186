import React from 'react';
import classNames from 'classnames';

import Checkbox from '../checkbox';
import Label from '../label';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import { MultiSelectProps } from './multi-select.props';

import './multi-select.scss';

const MultiSelect = React.forwardRef<HTMLButtonElement, MultiSelectProps>(
  ({ className, items, label, onChange, value }: MultiSelectProps, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [listSelectedId, setListSelectedId] = React.useState<string[]>(value);
    const multiSelectRef = React.useRef<HTMLDivElement | null>(null);
    const filteredItemsId = React.useMemo(() => items.map((it) => it.id), [items]);

    const handleChange = () => {
      if (listSelectedId.length === 0) {
        setListSelectedId(filteredItemsId);
        onChange(filteredItemsId);
      } else {
        onChange(listSelectedId);
      }
      setIsOpen(false);
    };

    useOnClickOutside(multiSelectRef, handleChange);

    const handleButtonClick = () => {
      if (isOpen) {
        handleChange();
        return;
      }
      setIsOpen(true);
    };

    const handleClickItem = (id: string, isChecked: boolean) =>
      isChecked
        ? setListSelectedId((prev) => prev.filter((p) => p !== id))
        : setListSelectedId((prev) => [...prev, id]);

    const getListValueLabel = (listId: string[]) => {
      return (
        items
          .filter((item) => listId.includes(item.id))
          .map((item) => item.value)
          .join(', ') || ''
      );
    };

    const getCurrentValueLabel = () => {
      return listSelectedId.length === items.length || listSelectedId.length === 0
        ? 'Все'
        : getListValueLabel(listSelectedId);
    };

    React.useEffect(() => {
      setListSelectedId(value);
    }, [value]);

    const currentValueLabel = getCurrentValueLabel();

    const multiSelectClasses = classNames('multi-select', className, {
      'multi-select_open': isOpen,
      'multi-select_label': !!label,
    });

    const labelClasses = classNames('multi-select__label label_select', {
      label_placeholder: !isOpen && !currentValueLabel,
    });

    return (
      <div className={multiSelectClasses} ref={multiSelectRef}>
        <button
          type="button"
          onClick={handleButtonClick}
          className="multi-select__button"
          ref={ref}
        >
          {label ? <Label label={label} className={labelClasses} /> : null}
          <span className="multi-select__value">{getCurrentValueLabel()}</span>
          <ArrowIcon className="multi-select__arrow" />
        </button>
        {isOpen && (
          <div className="multi-select__dropbox scrollbar">
            {items.length > 0 ? (
              <ul className="multi-select__dropbox-list">
                {items.map((item) => {
                  const isChecked = listSelectedId.includes(item.id);
                  const label = item.value;

                  return (
                    <li className="multi-select__dropbox-list-item" key={item.id}>
                      <Checkbox
                        id={item.id}
                        isChecked={isChecked}
                        onChange={() => handleClickItem(item.id, isChecked)}
                        label={label}
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="multi-select__no-data">Нет данных</div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default MultiSelect;
