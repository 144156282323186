import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Button from 'src/components/ui/button';
import Checkbox from 'src/components/ui/checkbox';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import { SelectOption } from 'src/interfaces/select-option.interface';
import useOnClickOutside from 'src/hooks/use-on-click-outside';

import '../popup.scss';

const SELECT_WORKERS_POPUP = POPUP_NAMES.SELECT_WORKERS_POPUP;

export const SelectWorkersPopup = observer(() => {
  const selectWorkersPopupRef = React.useRef<HTMLDivElement | null>(null);

  const { isVisible, listWorkers, onSubmit, selectedWorkersIds } =
    popupStore.getPopupState(SELECT_WORKERS_POPUP);

  const [listSelectedId, setListSelectedId] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (selectedWorkersIds) {
      setListSelectedId(selectedWorkersIds);
    }
  }, [selectedWorkersIds]);

  const closePopup = () => {
    popupStore.hidePopup(SELECT_WORKERS_POPUP);
    setListSelectedId([]);
  };

  const handleSubmit = () => {
    closePopup();
    onSubmit?.(listSelectedId);
  };

  useOnClickOutside(selectWorkersPopupRef, closePopup, !isVisible);

  const handleClickItem = (id: string, isChecked: boolean) =>
    isChecked
      ? setListSelectedId((prev) => prev.filter((p) => p !== id))
      : setListSelectedId((prev) => [...prev, id]);

  return (
    <div
      className={classNames('popup popup_select-workers', isVisible && 'popup_visible')}
      ref={selectWorkersPopupRef}
    >
      <div className="popup__title-wrapper">
        <h3 className="popup__title">Выбор работников</h3>

        <p className="popup__title-description">Укажите работников для формирования бригады</p>
      </div>

      <ul className="popup__items-wrapper scrollbar">
        {listWorkers
          ? (listWorkers as SelectOption[]).map(({ id, value }) => {
              const isChecked = listSelectedId.includes(id);

              return (
                <li key={id} className="popup__item">
                  <Checkbox
                    id={id}
                    isChecked={isChecked}
                    label={value}
                    onChange={() => handleClickItem(id, isChecked)}
                  />
                </li>
              );
            })
          : null}
      </ul>

      <div className="popup__actions">
        <Button
          isDisabled={!listSelectedId.length}
          label="Сформировать бригаду"
          onClick={handleSubmit}
          theme="filled"
        />

        <Button
          className="popup__cancel-button"
          label="Отмена"
          onClick={closePopup}
          theme="borderless"
        />
      </div>
    </div>
  );
});

export default SelectWorkersPopup;
