import React from 'react';

import { InputPlaceholderProps } from './input-placeholder.props';

import './input-placeholder.scss';

function InputPlaceholder({ placeholder, hide }: InputPlaceholderProps) {
  return !hide && placeholder ? <span className="input__placeholder">{placeholder}</span> : null;
}

export default InputPlaceholder;
