import { Route } from 'react-router-dom';

import ControlRoomPage from 'src/pages/control-room-page';
import LoginPage from 'src/pages/login-page';
import MapPage from 'src/pages/map-page';
import MainPageLayout from 'src/layouts/main-page-layout';
import PrivateRoute from 'src/components/common/private-route';
import SortingCenterPage from 'src/pages/sorting-center-page';

import { AppRoute } from 'src/constants';

const LOG_IN_PAGE_ROUTE = [{ path: AppRoute.LOGIN_PAGE_ROUTE, component: LoginPage }];

const MAIN_ROUTES = [
  { path: AppRoute.CONTROL_ROOM_PAGE_ROUTE, component: ControlRoomPage },
  { path: AppRoute.SORTING_CENTER_PAGE_ROUTE, component: SortingCenterPage },
  { path: AppRoute.MAP_PAGE_ROUTE, component: MapPage },
];

export function MainPageRoutes() {
  return MAIN_ROUTES.map(({ path, component: Component }) => (
    <PrivateRoute
      key={path}
      path={path}
      exact
      render={() => (
        <MainPageLayout>
          <Component />
        </MainPageLayout>
      )}
    />
  ));
}

export function LogInPageRoutes() {
  return LOG_IN_PAGE_ROUTE.map(({ path, component: Component }) => (
    <Route key={path} path={path} exact>
      <Component />
    </Route>
  ));
}
