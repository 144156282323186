import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Logo from 'src/assets/icons/logo.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';
import { ReactComponent as MapIcon } from 'src/assets/icons/map.svg';
import { ReactComponent as MonitoringIcon } from 'src/assets/icons/monitoring.svg';
import { ReactComponent as SortingIcon } from 'src/assets/icons/sorting.svg';

import globalAppStore from 'src/stores/global-app-store';

import { AppRoute } from 'src/constants/app-routes';
import { Role } from 'src/constants';

import './navbar.scss';

function Navbar() {
  const role = globalAppStore.role;
  const isFarewellsAvailable = role === Role.DISPATCHER || role === Role.SUPERVISOR;
  const isSortingCenterAvailable = role === Role.OPERATOR || role === Role.SUPERVISOR;

  return (
    <nav className="navbar">
      <img className="navbar__logo" src={Logo} alt="Логотип Честный Агент" width="32" height="32" />

      <ul className="navbar__menu">
        {isFarewellsAvailable && (
          <li className="navbar__item">
            <NavLink
              aria-label="Диспетчерская"
              to={AppRoute.CONTROL_ROOM_PAGE_ROUTE}
              className={(isActive) =>
                classNames('navbar__item-link', isActive && 'navbar__item-link_active')
              }
            >
              <MonitoringIcon />
            </NavLink>
          </li>
        )}
        {isSortingCenterAvailable && (
          <li className="navbar__item">
            <NavLink
              aria-label="Сортировочный центр"
              to={AppRoute.SORTING_CENTER_PAGE_ROUTE}
              className={(isActive) =>
                classNames('navbar__item-link', isActive && 'navbar__item-link_active')
              }
            >
              <SortingIcon />
            </NavLink>
          </li>
        )}
        {isFarewellsAvailable && (
          <li className="navbar__item">
            <NavLink
              aria-label="Карта залов прощания"
              to={AppRoute.MAP_PAGE_ROUTE}
              className={(isActive) =>
                classNames('navbar__item-link', isActive && 'navbar__item-link_active')
              }
            >
              <MapIcon />
            </NavLink>
          </li>
        )}
      </ul>

      <ul className="navbar__menu navbar__menu_bottom">
        <li className="navbar__item">
          <button
            aria-label="Выйти"
            className="navbar__item-button"
            onClick={() => globalAppStore.logOut()}
          >
            <ExitIcon />
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
