export function downloadFile(data: Blob, fileName: string) {
  const file = new Blob([data]);
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.download = `${fileName}.xlsx`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
