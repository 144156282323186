import React from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import Input from 'src/components/ui/input';
import Button from 'src/components/ui/button';

import AuthStore from 'src/stores/auth-store';

import { INPUT_PHONE_MASK, Key } from 'src/constants';
import { getPhoneMask } from 'src/utils';

const PhoneInputForm = observer(({ store }: { store: AuthStore }) => {
  const isCheckPhoneAvailable = store.isPhoneValid && !store.isLoading;

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === Key.ENTER && isCheckPhoneAvailable) {
      store.getVerificationCode();
    }
  };

  return (
    <>
      <Input
        id={uuidv4()}
        onChange={store.setPhone}
        onKeyPress={handleKeyPress}
        renderCustomMaskText={getPhoneMask}
        value={store.phone}
        placeholder="Номер телефона"
        mask={INPUT_PHONE_MASK}
        maskPlaceholder={'X'}
      />
      <Button
        className="login-page__button"
        isDisabled={!isCheckPhoneAvailable}
        onClick={store.getVerificationCode}
        label="Получить код"
        theme="filled"
        type="submit"
      />
    </>
  );
});

export default PhoneInputForm;
