import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';

import Input from 'src/components/ui/input';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import { OrderSearchProps } from './order-seacrh.props';

const OrderSearch = observer(({ placeholder, className, store }: OrderSearchProps) => (
  <Input
    className={className}
    id={uuidv4()}
    icon={<SearchIcon />}
    placeholder={placeholder}
    onChange={store.changeSearchStr}
    type="search"
    value={store.searchStr}
  />
));

export default OrderSearch;
