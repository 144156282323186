import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import DatePicker from '../date-picker';

import farewellStore from 'src/stores/control-room/farewell-store';
import cremationStore from 'src/stores/sorting-center/cremation-store';

import { DateStatProps } from './date-stat.props';
import { AppRoute } from 'src/constants';

import './date-stat.scss';

const DateStat = observer(({ className, store, page }: DateStatProps) => (
  <div className={classNames('date-stat', className)}>
    <div className="date-stat__values">
      {page === AppRoute.SORTING_CENTER_PAGE_ROUTE ? (
        <p className="date-stat__values-item">
          Заказы:&nbsp;
          <span className="date-stat__values-item-count">{cremationStore.totalCremationCount}</span>
        </p>
      ) : (
        <>
          <p className="date-stat__values-item">
            Заказы:&nbsp;
            <span className="date-stat__values-item-count">{farewellStore.totalOrdersCount}</span>
          </p>
          <p className="date-stat__values-item">
            Предзаказы:&nbsp;
            <span className="date-stat__values-item-count">
              {farewellStore.totalPreOrdersCount}
            </span>
          </p>
        </>
      )}
    </div>
    <DatePicker store={store} />
  </div>
));

export default DateStat;
