import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from './button.props';

import './button.scss';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ariaLabel, className, icon, isDisabled, label, onClick, size, style, theme, type }, ref) => {
    const classes = classNames(
      'button',
      !!icon && `button_icon`,
      !!theme && `button_${theme}`,
      !!size && `button_${size}`,
      className,
      {
        button_disabled: isDisabled,
      }
    );

    return (
      <button
        aria-label={ariaLabel}
        className={classes}
        onClick={onClick}
        ref={ref}
        style={style}
        type={type}
        disabled={isDisabled}
      >
        {icon}
        {label}
      </button>
    );
  }
);

export default Button;
