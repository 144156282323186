import { makeAutoObservable } from 'mobx';

import filtersStore from './filters-store';
import globalAppStore from 'src/stores/global-app-store';

import { ICremationApi } from 'src/interfaces/cremations-api.interface';
import { ICremation } from 'src/interfaces/cremations.interface';
import { getFullName, mapApiToDeceased } from 'src/utils';
import { doPatch } from 'src/api';

class CremationStore {
  cremationsData: ICremation[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.patchCremation = this.patchCremation.bind(this);
  }

  setCremations(cremations: ICremationApi[]) {
    this.setIsLoading(false);
    this.cremationsData = mapApiToCremations(cremations);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  get cremations() {
    return this.cremationsData;
  }

  get filteredCremations() {
    let cremations = [...this.cremations];

    if (filtersStore.isUrgent) {
      cremations = cremations.filter((cremation) => cremation.urgent);
    }

    if (filtersStore.isUnallocated) {
      cremations = cremations.filter((cremation) => !cremation.crematorium?.id);
    }

    if (filtersStore.searchStr) {
      cremations = cremations.filter(({ deceased }) =>
        getFullName(deceased).toLowerCase().includes(filtersStore.searchStr.toLowerCase())
      );
    }

    if (globalAppStore.crematoriums.length !== filtersStore.selectedCrematoriesIds.length) {
      cremations = cremations.filter((cremation) =>
        filtersStore.selectedCrematoriesIds.includes(cremation.crematorium?.id as string)
      );
    }

    return cremations;
  }

  get totalCremationCount() {
    return this.cremations.length;
  }

  get isDayClosed() {
    return !!this.cremations?.some(({ closed }) => closed);
  }

  getCremationCountByCrematorium(crematoriumId: string) {
    return (
      this.cremations.filter((cremation) => cremation.crematorium?.id === crematoriumId).length || 0
    );
  }

  async patchCremation(radioId: string) {
    const [cremationId, crematorium] = radioId.split('_');

    try {
      await doPatch(`/cremations/${cremationId}/crematorium`, { crematorium });
    } catch (e) {
      console.log('e', e);
    }
  }
}

function mapApiToCremations(items: ICremationApi[]) {
  return items.map(({ deceased, hall_name, show_date, ...rest }) => ({
    deceased: mapApiToDeceased(deceased),
    hallName: hall_name,
    showDate: show_date,
    ...rest,
  }));
}

export default new CremationStore();
