import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Navbar from 'src/components/common/navbar';
import TopPanel from 'src/components/common/top-panel';

import { MainPageLayoutProps } from './main-page-layout.props';

import './main-page-layout.scss';

function MainPageLayout({ children, className }: MainPageLayoutProps) {
  const classes = classNames('main-page-layout', className);
  const { pathname } = useLocation();

  return (
    <div className={classes}>
      <Navbar />

      <TopPanel page={pathname} />

      {children}
    </div>
  );
}

export default MainPageLayout;
