import React from 'react';
import ReactDOM from 'react-dom';

import { TooltipProps } from './tooltip.props';

import './tooltip.scss';

function Tooltip({ text, style }: TooltipProps) {
  const el = React.useMemo(() => document.createElement('div'), []);

  const Component = () => (
    <div className="tooltip" style={style}>
      {text}
    </div>
  );

  React.useEffect(() => {
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(<Component />, el);
}

export default Tooltip;
