import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';

import { OrderTableHeadProps } from './order-table-head.props';

import './order-table-head.scss';

const OrderTableHead = React.forwardRef<HTMLTableSectionElement, OrderTableHeadProps>(
  ({ isShowAlertIcon, ordersCount, preOrdersCount, title, onClick }: OrderTableHeadProps, ref) => {
    const [isIconShowed, setIsIconShowed] = React.useState(false);
    const [isIconDisappears, setIsIconDisappears] = React.useState(false);

    React.useEffect(() => {
      if (isShowAlertIcon && !isIconShowed) {
        setIsIconShowed(true);
        setIsIconDisappears(false);
      }

      if (!isShowAlertIcon && isIconShowed) {
        setIsIconShowed(false);
        setIsIconDisappears(true);
      }
    }, [isShowAlertIcon, isIconShowed]);

    const iconClasses = classNames(
      'order-table-head__warning-icon',
      isIconShowed && 'order-table-head__warning-icon_showed',
      isIconDisappears && 'order-table-head__warning-icon_disappearance'
    );

    return (
      <thead onClick={onClick} ref={ref}>
        <tr className="table__row order-table-head">
          <th className="order-table-head__cell order-table-head__cell_first">{title}</th>

          <th className="order-table-head__cell">
            <WarningIcon className={iconClasses} height={20} width={20} />
          </th>

          <th className="order-table-head__cell order-table-head__cell_last">
            <span className="order-table-head__order-information">
              {ordersCount} ({preOrdersCount}){' '}
              <span className="order-table-head__icon-wrapper">
                <ArrowIcon className="order-table-head__icon" height={20} width={20} />
              </span>
            </span>
          </th>
        </tr>
      </thead>
    );
  }
);
export default OrderTableHead;
