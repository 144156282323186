import { SelectOption } from 'src/interfaces/select-option.interface';

export function isEqualItems(a: SelectOption[], b: SelectOption[]) {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i].id !== b[i].id) {
      return false;
    }
  }

  return true;
}
