import { makeAutoObservable, reaction } from 'mobx';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import globalAlertStore from './global-alert-store';
import globalAppStore from './global-app-store';

import { doPost } from '../api';
import { getLocalStorageItem, setLocalStorageItem } from 'src/helpers/localStorageHelper';
import {
  ApiResponseStatus,
  APP_LOCALSTORAGE_CODE_EXPIRED_KEY,
  APP_LOCALSTORAGE_PHONE_KEY,
  Role,
} from 'src/constants';
import { AlertType } from 'src/components/ui/alert';

const EMPTY_PHONE = '';
const EMPTY_CODE = ['', '', '', ''];
const FIRST_CODE_INPUT_NUMBER = 0;

export enum LoginStep {
  PHONE_INPUT,
  CODE_INPUT,
  CODE_CHECKING,
  CODE_SUCCESS,
  CODE_FAILED,
}

class AuthStore {
  init() {
    const code = getLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY);
    const phone = getLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY);

    if (!this.isCodeTimerRunning()) {
      setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, '');
    } else {
      this.runCodeTimer();
    }

    if (code && phone) {
      this.setPhone(phone);
      this.setLoginStep(LoginStep.CODE_INPUT);
    }
  }

  phone = EMPTY_PHONE;
  code = EMPTY_CODE;
  activeCodeInputNumber = FIRST_CODE_INPUT_NUMBER;
  loginStep = LoginStep.PHONE_INPUT;
  codeTimerId: number | null = null;
  codeTimerRemain = 0;
  isNewCodeRequested = false;
  isCodeFailed = false;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.isFormCodeValid,
      () => this.checkFormCode()
    );

    reaction(
      () => this.codeTimerRemain,
      (timer) => timer <= 0 && this.clearCodeTimer()
    );

    this.init();

    this.setPhone = this.setPhone.bind(this);
    this.resetPhone = this.resetPhone.bind(this);
    this.checkFormCode = this.checkFormCode.bind(this);
    this.getVerificationCode = this.getVerificationCode.bind(this);
    this.getNewVerificationCode = this.getNewVerificationCode.bind(this);
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setCode(code: string[]) {
    this.code = code;
  }

  setLoginStep(step: LoginStep) {
    this.loginStep = step;
  }

  setActiveCodeInputNumber(number: number) {
    this.activeCodeInputNumber = number;
  }

  setIsNewCodeRequested(isRequested: boolean) {
    this.isNewCodeRequested = isRequested;
  }

  setCodeTimeRemain(seconds: number) {
    this.codeTimerRemain = seconds;
  }

  setCodeTimerId(id: any) {
    this.codeTimerId = id;
  }

  setIsCodeFailed(isCodeFailed: boolean) {
    this.isCodeFailed = isCodeFailed;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  get step() {
    return this.loginStep;
  }

  get isPhoneValid() {
    const re = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
    return re.test(this.phone) || this.isLoading;
  }

  get isFormCodeValid() {
    return this.code.every(Boolean);
  }

  get isNewCodeRequestDisabled() {
    return this.codeTimerRemain > 0;
  }

  getTimerExpired() {
    const codeTimerExpired = getLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY);

    return codeTimerExpired ? parseInt(codeTimerExpired, 10) : 0;
  }

  isCodeTimerRunning() {
    const codeTimeExpired = this.getTimerExpired();
    const nowTime = dayjs().unix();

    return codeTimeExpired && codeTimeExpired - nowTime > 0;
  }

  getCodeInputsProps(number: number) {
    return {
      value: this.code[number],
      onChange: (e: any) => {
        const symbol = e.slice(-1);
        let verifiedSymbol = '';

        if (/[0-9]/.test(symbol)) {
          verifiedSymbol = symbol;
        }

        this.setCode([
          ...this.code.slice(0, number),
          verifiedSymbol,
          ...this.code.slice(number + 1),
        ]);

        if (verifiedSymbol && verifiedSymbol === symbol) {
          this.setActiveCodeInputNumber(number < 3 ? number + 1 : number);
        }
        if (!symbol) {
          this.setActiveCodeInputNumber(number > 0 ? number - 1 : number);
        }
      },
    };
  }

  async getVerificationCode() {
    const phone = getLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY);

    this.setIsNewCodeRequested(false);

    if (phone && phone === this.phone && this.isCodeTimerRunning()) {
      this.runCodeTimer();
      this.setLoginStep(LoginStep.CODE_INPUT);

      return;
    }

    if (phone !== this.phone) {
      setLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY, this.phone);
    }

    await this.doSendCodeApiRequest();
  }

  async getNewVerificationCode() {
    this.setCode(EMPTY_CODE);
    this.setActiveCodeInputNumber(FIRST_CODE_INPUT_NUMBER);
    this.setIsNewCodeRequested(true);

    const newCodeTimeExpired = dayjs().add(59, 's').unix().toString();

    setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, newCodeTimeExpired);
    setLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY, this.phone);

    await this.doSendCodeApiRequest();
  }

  async doSendCodeApiRequest() {
    this.setIsLoading(true);

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
      };

      const result = await doPost('/signin/phone', options);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const codeTimeExpired = dayjs().add(59, 's').unix().toString();

        setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, codeTimeExpired);

        this.runCodeTimer();
        this.setLoginStep(LoginStep.CODE_INPUT);
      }
    } catch (e) {
      console.log('ERROR: ', e);
    }

    this.setIsLoading(false);
  }

  async checkFormCode() {
    if (!this.isFormCodeValid && this.isLoginStep(LoginStep.CODE_FAILED)) {
      this.setLoginStep(LoginStep.CODE_INPUT);

      return;
    }

    if (!this.isFormCodeValid || !this.isLoginStep(LoginStep.CODE_INPUT)) {
      return;
    }

    this.setLoginStep(LoginStep.CODE_CHECKING);

    this.setIsLoading(true);

    this.setActiveCodeInputNumber(FIRST_CODE_INPUT_NUMBER);

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
        code: this.code.join(''),
      };

      const result = await doPost('/signin/auth', options);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const token = result.headers?.authorization?.split('Bearer ')?.[1] || '';

        if (result?.data?.role === Role.SORTER) {
          this.clearLocalStorage();
          this.setCode(EMPTY_CODE);
          this.setLoginStep(LoginStep.PHONE_INPUT);
          globalAlertStore.addAlert({
            id: uuidv4(),
            desc: 'Недостаточно прав для доступа к веб интерфейсу',
            timeout: 10000,
            title: 'Ошибка доступа',
            type: AlertType.error,
          });
        } else {
          globalAppStore.setToken(token);

          this.clearLocalStorage();
          this.setLoginStep(LoginStep.CODE_SUCCESS);
        }
      }
    } catch (e) {
      this.setActiveCodeInputNumber(FIRST_CODE_INPUT_NUMBER);
      this.setCode(EMPTY_CODE);
      this.setIsCodeFailed(true);
      this.setLoginStep(LoginStep.CODE_INPUT);
      console.log('error', e);
    }

    this.setIsLoading(false);
  }

  runCodeTimer() {
    this.updateCodeTimeRemain();

    const timerId = setInterval(() => this.updateCodeTimeRemain(), 1000);

    this.setCodeTimerId(timerId);
  }

  clearCodeTimer() {
    this.setCodeTimeRemain(0);
    this.setIsNewCodeRequested(false);

    this.clear();

    setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, '');
  }

  updateCodeTimeRemain() {
    const nowTime = dayjs().unix();
    const timeDiff = this.getTimerExpired() - nowTime;

    if (timeDiff > 0) {
      this.setCodeTimeRemain(timeDiff);
    } else {
      this.clearCodeTimer();
    }
  }

  isLoginStep(step: LoginStep) {
    return step === this.step;
  }

  resetPhone() {
    this.setLoginStep(LoginStep.PHONE_INPUT);
    this.setPhone(EMPTY_PHONE);
    this.setCode(EMPTY_CODE);
    this.setIsNewCodeRequested(false);
    this.setIsCodeFailed(false);
    this.setActiveCodeInputNumber(FIRST_CODE_INPUT_NUMBER);
  }

  clearLocalStorage() {
    setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, '');
    setLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY, '');
  }

  clear() {
    if (this.codeTimerId) {
      clearInterval(this.codeTimerId);
    }
  }
}

export default AuthStore;
