import React from 'react';
import classNames from 'classnames';

import farewellStore from 'src/stores/control-room/farewell-store';

import { FarewellType } from 'src/constants';
import { OrderTableRowProps } from './order-table-row.props';
import {
  getFullName,
  getLifeDates,
  getNameWithDots,
  getParsedTime,
  getParsedDate,
} from 'src/utils';

import './order-table-row.scss';

const renderLabel = (type: string, canceled: boolean) => {
  if (canceled) {
    return (
      <span className="order-table-row__label order-table-row__label_canceled">Аннулирован</span>
    );
  }
  switch (type) {
    case FarewellType.ORDER:
      return <span className="order-table-row__label order-table-row__label_order">Заказ</span>;

    case FarewellType.PRE_ORDER:
      return (
        <span className="order-table-row__label order-table-row__label_pre-order">Предзаказ</span>
      );

    default:
      return null;
  }
};

function OrderTableRow({
  agent,
  canceled,
  comments,
  date,
  deceased,
  id,
  isLast = false,
  isTableExpanded,
  new: newRow,
  type,
  urgent,
}: OrderTableRowProps) {
  const [isAnimated, setIsAnimated] = React.useState(false);
  const [isBackgroundDisappears, setIsBackgroundDisappears] = React.useState(false);
  const [style, setStyle] = React.useState({
    height: '',
  });

  const rowRef: React.MutableRefObject<null | HTMLTableRowElement> = React.useRef(null);

  const deceasedFullName = getFullName(deceased);

  const deceasedDates = getLifeDates(deceased);

  const agentFullName = getNameWithDots(agent);

  const rowClasses = classNames('order-table__row', {
    'order-table__row_canceled': canceled,
    'order-table__row_last': isLast,
    'order-table__row_new': newRow,
    'order-table__row_hover-transition': !newRow,
    'order-table__row_background-disappearance': isBackgroundDisappears,
  });

  React.useEffect(() => {
    if (newRow && isTableExpanded) {
      if (isAnimated) {
        return;
      }

      setIsAnimated(true);

      const height = `${rowRef.current?.scrollHeight ?? 0}px`;

      setStyle({ height });
    }
  }, [newRow, isAnimated, isTableExpanded]);

  React.useEffect(() => {
    const rowEl = rowRef.current;

    const handleTransitionEnd = (e: any) => {
      if (isAnimated && e?.target?.tagName === 'TR' && e?.propertyName === 'height') {
        setIsBackgroundDisappears(true);
        setStyle(() => ({ height: '' }));
      }

      if (isAnimated && e?.target?.tagName === 'TR' && e?.propertyName === 'background-color') {
        farewellStore.onTableExpanded(id);
        setIsBackgroundDisappears(false);
        setIsAnimated(false);
      }

      if (rowEl && !isAnimated) {
        rowEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };

    if (rowEl && isAnimated) {
      rowEl.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (rowEl) {
        rowEl.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [isAnimated, id]);

  return (
    <tr className={rowClasses} style={style} ref={rowRef}>
      <td className="order-table-row__cell order-table-row__cell_date">
        <span className="table__text-wrapper">
          <span className="table__text">{getParsedDate(date)}</span>

          <span className="table__small-text">{getParsedTime(date)}</span>
        </span>
      </td>

      <td className="order-table-row__cell">
        <span className="table__text-wrapper">
          <span className="table__text">{deceasedFullName}</span>

          <span className="table__small-text">{deceasedDates}</span>
        </span>
      </td>

      <td className="order-table-row__cell">
        <span className="table__text-wrapper">
          <span className="table__text">{agentFullName}</span>

          <span className="table__small-text">{agent?.phone}</span>
        </span>
      </td>

      <td className="order-table-row__cell">
        <span className="table__text">{comments ? comments : '-'}</span>
      </td>

      <td className="order-table-row__cell">
        {urgent ? <span className="table__status">c</span> : ''}
      </td>

      <td className="order-table-row__cell order-table-row__cell_status">
        {renderLabel(type, canceled)}
      </td>
    </tr>
  );
}
export default OrderTableRow;
