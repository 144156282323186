import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import PhoneInputForm from './components/phone-input-form';
import CodeInputForm from './components/code-input-form';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';

import AuthStore, { LoginStep } from 'src/stores/auth-store';
import globalAppStore from 'src/stores/global-app-store';

import { getRootPage } from 'src/utils';

import './login-page.scss';

const LoginPage = observer(() => {
  const [authStore] = React.useState(() => new AuthStore());

  if (authStore.step === LoginStep.CODE_SUCCESS) {
    const rootPage = getRootPage(globalAppStore.role);

    return <Redirect to={rootPage} />;
  }

  return (
    <div className="login-page">
      <div className="login-page__logo-wrap">
        <div className="login-page__icon">
          <LogoIcon width={140} height={140} />
        </div>
        <div className="login-page__logo">
          <div className="login-page__title">Честный Агент</div>
          <div className="login-page__subtitle">Логистика и сортировка тел</div>
        </div>
      </div>

      <div className="login-page__form">
        {authStore.loginStep === LoginStep.PHONE_INPUT ? (
          <PhoneInputForm store={authStore} />
        ) : (
          <CodeInputForm store={authStore} />
        )}
      </div>
    </div>
  );
});

export default LoginPage;
