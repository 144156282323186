import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import ArrowButton from 'src/components/ui/arrow-button';
import Button from 'src/components/ui/button';
import DateFilters from 'src/components/common/date-filters';
import DatePickerModal from './components/date-picker-modal';

import DatePickerStore from 'src/stores/date-picker-store';

import { DatePickerProps } from './date-picker.props';
import { Key, PeriodLabel } from 'src/constants';
import useModal from 'src/hooks/use-modal';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useKeyUp from 'src/hooks/use-key-up';

import './date-picker.scss';

const DatePicker = observer(({ className, store }: DatePickerProps) => {
  dayjs.locale('ru');

  const wrapperRef = React.useRef(null);
  const { isModalShown, closeModal, toggleModal } = useModal();
  useOnClickOutside(wrapperRef, closeModal, !isModalShown);
  useKeyUp(Key.ESC, closeModal, !isModalShown);

  const incomingDay = dayjs(store.date).hour(0).minute(0).second(0).millisecond(0).toISOString();
  const [datePickerStore] = React.useState(() => new DatePickerStore());
  const isToday = datePickerStore.isToday(incomingDay);
  const isTomorrow = datePickerStore.isTomorrow(incomingDay);
  const isDayAfterTomorrow = datePickerStore.isDayAfterTomorrow(incomingDay);
  const date = datePickerStore.humanFormatDate(incomingDay);
  const day = isToday
    ? PeriodLabel.TODAY
    : isTomorrow
    ? PeriodLabel.TOMORROW
    : isDayAfterTomorrow
    ? PeriodLabel.DAY_AFTER_TOMORROW
    : dayjs(incomingDay).format('dddd');

  const handleDateSubmit = () => {
    if (datePickerStore.isCustomDateInputDisabled || datePickerStore.isCustomDateValid()) {
      store.setDate(datePickerStore.date);
      datePickerStore.setCustomDate('');
      closeModal();
    }
  };

  const handlePreviousButtonClick = () => {
    store.setPreviousDate();
    closeModal();
  };

  const handleNextButtonClick = () => {
    store.setNextDate();
    closeModal();
  };

  useKeyUp(Key.ENTER, handleDateSubmit, !isModalShown);

  React.useEffect(() => {
    if (incomingDay) {
      datePickerStore.setFilterValues(incomingDay);
    }
  }, [datePickerStore, incomingDay]);

  return (
    <div className={classNames('date-picker', className)} ref={wrapperRef}>
      <ArrowButton
        ariaLabel="Предыдущий день"
        className="date-picker__arrow-button"
        direction="left"
        onClick={handlePreviousButtonClick}
      />
      <button className="date-picker__toggle" onClick={toggleModal}>
        <span className="date-picker__toggle-wrapper">
          <span className="date-picker__toggle-date">{date}</span>
          <span className="date-picker__toggle-day">{day}</span>
        </span>
      </button>
      <ArrowButton
        ariaLabel="Следующий день"
        className="date-picker__arrow-button"
        direction="right"
        onClick={handleNextButtonClick}
      />
      {isModalShown && (
        <DatePickerModal className="date-picker__modal">
          <DateFilters className="date-picker__filters" store={datePickerStore} />
          <Button
            className="date-picker__submit-button"
            label="Применить"
            onClick={handleDateSubmit}
            theme="filled"
            type="submit"
          />
        </DatePickerModal>
      )}
    </div>
  );
});

export default DatePicker;
