import React from 'react';
import { observer } from 'mobx-react-lite';

import FilterPanelCheckboxes from '../filter-panel-checkboxes';
import FilterPanelCrematories from '../filter-panel-crematories';
import FilterPanelDates from '../filter-panel-dates';

import './filter-panel.scss';

const FilterPanel = observer(() => {
  return (
    <div className="filter-panel">
      <FilterPanelCrematories />

      <FilterPanelDates />

      <FilterPanelCheckboxes />
    </div>
  );
});

export default FilterPanel;
