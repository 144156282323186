import React from 'react';
import classNames from 'classnames';

import { CheckboxProps } from './checkbox.props';

import './checkbox.scss';

function Checkbox({ label, id, isChecked, isDisabled, onChange }: CheckboxProps) {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.checked);

  return (
    <div className={classNames('checkbox', isDisabled && 'checkbox_disabled')}>
      <input
        className="checkbox__input"
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <label htmlFor={id} className="checkbox__label">
        <span className="checkbox__icon" />
        <span className="checkbox__label-text">{label}</span>
      </label>
    </div>
  );
}

export default Checkbox;
