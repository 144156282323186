import React, { useCallback } from 'react';

import Tooltip from '../tooltip';

import { TooltipTriggerProps } from './tooltip-trigger.props';

function TooltipTrigger({ children, isHideOnScroll = true, text }: TooltipTriggerProps) {
  const tooltipRef = React.useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipStyle, setTooltipStyle] = React.useState({ left: '', top: '' });

  const handleTooltipEnter = () => {
    const { left, width, top } = tooltipRef.current!.getBoundingClientRect();
    setShowTooltip(true);
    setTooltipStyle({ left: `${left + width / 2}px`, top: `${top - 4}px` });
  };

  const hideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  React.useEffect(() => {
    if (isHideOnScroll) {
      if (showTooltip) {
        window.addEventListener('scroll', hideTooltip, true);
      } else {
        window.removeEventListener('scroll', hideTooltip, true);
      }
    }

    return () => {
      if (isHideOnScroll) {
        window.removeEventListener('scroll', hideTooltip, true);
      }
    };
  }, [hideTooltip, isHideOnScroll, showTooltip]);

  return (
    <div
      className="tooltip-trigger"
      onMouseEnter={handleTooltipEnter}
      onMouseLeave={hideTooltip}
      ref={tooltipRef}
    >
      {showTooltip && <Tooltip text={text} style={tooltipStyle} />}

      {children}
    </div>
  );
}

export default TooltipTrigger;
