import baseAxios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import globalAppStore from 'src/stores/global-app-store';
import globalAlertStore from 'src/stores/global-alert-store';

import { AlertType } from 'src/components/ui/alert';
import { ApiResponseStatus } from 'src/constants';

const api = baseAxios.create({
  baseURL: (window as any).API_URL,
});

api.interceptors.request.use(
  (options) => {
    const token = globalAppStore.token;
    if (token) {
      options.headers!.Authorization = `Bearer ${token}`;
    }
    return options;
  },
  (error) => {
    Promise.reject(error);
  }
);

interface IErrorResponse {
  status?: number;
  config?: {
    method?: string;
    url?: string;
  };
  data?: {
    error?: string;
  };
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, config = {}, data = {} } = (error?.response as IErrorResponse) || {};

    const { method, url } = config;
    const { error: errorText } = data;

    let title = `${status ?? ''}: ${method?.toUpperCase() ?? ''} ${url ?? ''}`;

    if (!status && !method && !url) {
      title = 'An unexpected error has occurred';
    }

    globalAlertStore.addAlert({
      id: uuidv4(),
      desc: errorText ?? '',
      timeout: 10000,
      title,
      type: AlertType.error,
    });

    if (status === ApiResponseStatus.UNAUTHORIZED) {
      globalAppStore.logOut();
    }

    return Promise.reject(error);
  }
);

export const doGet = (url: string, options?: any) => api.get(url, options);
export const doPost = (url: string, options: any) => api.post(url, options);
export const doPatch = (url: string, options: any) => api.patch(url, options);
export const doPut = (url: string, options: any) => api.put(url, options);
export const doDelete = (url: string, options: any) => api.delete(url, options);

export default api;
