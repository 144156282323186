import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import globalAppStore from 'src/stores/global-app-store';

import { AppRoute, Role } from 'src/constants';
import { getRootPage } from 'src/utils';
import { PrivateRouteProps } from './private-route.props';

const getAdequatePathRoles = (path: string) =>
  ({
    [AppRoute.CONTROL_ROOM_PAGE_ROUTE]: [Role.DISPATCHER, Role.SUPERVISOR],
    [AppRoute.SORTING_CENTER_PAGE_ROUTE]: [Role.OPERATOR, Role.SUPERVISOR],
    [AppRoute.MAP_PAGE_ROUTE]: [Role.DISPATCHER, Role.SUPERVISOR],
  }[path] || []);

function PrivateRoute({ render, path, exact }: PrivateRouteProps) {
  const role = globalAppStore.role;
  const rootPage = getRootPage(role) || '/';
  const isAdequateRolePath = getAdequatePathRoles(path).includes(role);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return isAdequateRolePath ? render() : <Redirect to={rootPage} />;
      }}
    />
  );
}

export default PrivateRoute;
