import React from 'react';
import classNames from 'classnames';

import { IRadioBox } from 'src/interfaces/radiobox.interface';

import './radiobox.scss';

function Radiobox({ idx, label, name, noLabel, isChecked, isDisabled, onChange }: IRadioBox) {
  const id = `radiobox_${idx}`;
  const value = noLabel ? idx : label;

  return (
    <div className={classNames('radiobox', isDisabled && 'radiobox_disabled')}>
      <input
        checked={isChecked}
        className="radiobox__input"
        id={id}
        name={name}
        disabled={isDisabled}
        onChange={() => onChange(value || '')}
        type="radio"
      />
      <label htmlFor={id} className="radiobox__label">
        <span className="radiobox__icon" />
        {label}
      </label>
    </div>
  );
}

export default Radiobox;
