import React from 'react';

import FilterPanel from './components/filter-panel';
import ListDocuments from './components/list-documents';
import SortingTable from './components/sorting-table';

import './sorting-center-page.scss';

function SortingCenterPage() {
  const [isListDocumentsCollapsed, setIsListDocumentsCollapsed] = React.useState(false);

  const onClick = () => setIsListDocumentsCollapsed((prev) => !prev);

  return (
    <div className="sorting-center-page">
      <FilterPanel />

      <SortingTable />

      <ListDocuments isCollapsed={isListDocumentsCollapsed} onClick={onClick} />
    </div>
  );
}

export default SortingCenterPage;
