import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AlertBlock from 'src/components/common/alert-block';
import AllPopups from 'src/components/app/all-popups';
import Loader from 'src/components/common/loader';

import globalAppStore from 'src/stores/global-app-store';

import { LogInPageRoutes, MainPageRoutes } from 'src/routes';
import { AppRoute } from 'src/constants';
import { getRootPage } from './utils';

import './scss/index.scss';

globalAppStore.init();

const App = observer(() => {
  const rootPage = getRootPage(globalAppStore.role);

  if (!rootPage && globalAppStore.token) {
    globalAppStore.logOut();
  }

  return (
    <div className="app">
      <Router>
        {globalAppStore.token ? (
          <Switch>
            {MainPageRoutes()}
            <Redirect to={rootPage} />
          </Switch>
        ) : (
          <Switch>
            {LogInPageRoutes()}
            <Redirect to={AppRoute.LOGIN_PAGE_ROUTE} />
          </Switch>
        )}
      </Router>
      <div className="app__shadow" />
      <AlertBlock />
      <AllPopups />
      {globalAppStore.isLoading ? <Loader className="loader_position_overlay" /> : null}
    </div>
  );
});

export default App;
