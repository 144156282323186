import { makeAutoObservable, reaction } from 'mobx';
import dayjs from 'dayjs';

import cremationStore from './cremation-store';

import { ICrematorium } from 'src/interfaces/crematorium.interface';
import { doPost } from 'src/api';
import { DATE_FORMAT_API, REQUEST_DELAY } from 'src/constants';

class FiltersStore {
  date: string = dayjs().toISOString();
  isUnallocated: boolean = false;
  isUrgent: boolean = false;
  searchStr: string = '';
  selectedCrematoriesIds: string[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.date,
      (date) => this.changeDate(date),
      { delay: REQUEST_DELAY }
    );

    reaction(
      () => this.date,
      () => cremationStore.setIsLoading(true)
    );
  }

  changeSearchStr(newSearchStr: string) {
    this.searchStr = newSearchStr;
  }

  setDate(date: string) {
    this.date = dayjs(date).toISOString();
  }

  setNextDate() {
    this.date = dayjs(this.date).add(1, 'day').toISOString();
  }

  setPreviousDate() {
    this.date = dayjs(this.date).add(-1, 'day').toISOString();
  }

  setSelectedCrematoriesIds(ids: string[]) {
    this.selectedCrematoriesIds = ids;
  }

  setCrematories(items: ICrematorium[]) {
    const ids = items.map((crematory) => crematory.id);
    this.setSelectedCrematoriesIds(ids);
  }

  setIsUnallocated(isUnallocated: boolean) {
    this.isUnallocated = isUnallocated;
  }

  setIsUrgent(isUrgent: boolean) {
    this.isUrgent = isUrgent;
  }

  toggleIsUnallocated() {
    this.setIsUnallocated(!this.isUnallocated);
  }

  toggleIsUrgent() {
    this.setIsUrgent(!this.isUrgent);
  }

  async changeDate(date: string) {
    try {
      await doPost('/eventdate', {
        name: 'cremations',
        date: dayjs(date).format(DATE_FORMAT_API),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async initDate() {
    await this.changeDate(this.date);
  }
}

export default new FiltersStore();
