import React from 'react';

export default function useModal() {
  const [isModalShown, setIsModalShown] = React.useState(false);

  const openModal = () => setIsModalShown(true);

  const closeModal = () => setIsModalShown(false);

  const toggleModal = () => setIsModalShown((prevState) => !prevState);

  return {
    isModalShown,
    closeModal,
    openModal,
    toggleModal,
  };
}
