import React from 'react';
import { observer } from 'mobx-react-lite';

import Checkbox from 'src/components/ui/checkbox';

import filtersStore from 'src/stores/sorting-center/filters-store';

const FilterPanelCheckboxes = observer(() => {
  return (
    <section className="filter-panel__section filter-panel__section_checkboxes">
      <h2 className="filter-panel__section-title">Отображать только</h2>

      <Checkbox
        id="filter-panel-urgent"
        isChecked={filtersStore.isUrgent}
        label="Срочные"
        onChange={filtersStore.toggleIsUrgent}
      />

      <Checkbox
        id="filter-panel-unSorted"
        isChecked={filtersStore.isUnallocated}
        label="Нераспределенные"
        onChange={filtersStore.toggleIsUnallocated}
      />
    </section>
  );
});

export default FilterPanelCheckboxes;
