import React from 'react';
import classNames from 'classnames';

import { EmptyDataProps } from './empty-data.props';
import useDebouce from 'src/hooks/use-debounce';

import './empty-data.scss';

function EmptyData({ className, icon, text, title, noDebounce = false }: EmptyDataProps) {
  const classes = classNames('empty-data', className);
  const { showComponent } = useDebouce(noDebounce);

  return showComponent ? (
    <div className={classes}>
      <span className="empty-data__icon">{icon}</span>

      <div className="empty-data__text-wrapper">
        <h4 className="empty-data__title">{title}</h4>

        <p className="empty-data__text">{text}</p>
      </div>
    </div>
  ) : null;
}
export default EmptyData;
