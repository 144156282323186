import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import Input from 'src/components/ui/input';
import Radiobox from 'src/components/ui/radiobox';

import DatePickerStore from 'src/stores/date-picker-store';
import filtersStore from 'src/stores/sorting-center/filters-store';

import { FILTERS_PERIODS, PeriodLabel, DATE_MASK, DATE_MASK_PLACEHOLDER, Key } from 'src/constants';
import { getDateMask } from 'src/utils';

const FilterPanelDates = observer(() => {
  const incomingDay = dayjs(filtersStore.date)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();
  const [datePickerStore] = React.useState(() => new DatePickerStore());

  React.useEffect(() => {
    if (incomingDay) {
      datePickerStore.setFilterValues(incomingDay);
    }
  }, [datePickerStore, incomingDay]);

  const onRadioboxChange = (label: string) => {
    datePickerStore.setCheckedPeriod(label);

    if (label !== PeriodLabel.OTHER_DAY) {
      filtersStore.setDate(datePickerStore.date);
    }
  };

  const onInputBlur = () => {
    if (!!datePickerStore.customDate && datePickerStore.isCustomDateValid()) {
      filtersStore.setDate(datePickerStore.date);
    }
  };

  const onInputKeyPress = (
    evt: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (evt.key === Key.ENTER) {
      onInputBlur();
    }
  };

  return (
    <section className="filter-panel__section filter-panel__section_date">
      <h2 className="filter-panel__section-title">Дата церемонии</h2>

      {FILTERS_PERIODS.map((label, idx) => (
        <Radiobox
          key={idx}
          idx={`filter_panel_${idx}`}
          isChecked={label === datePickerStore.checkedPeriod}
          label={label}
          name="filter-period"
          onChange={onRadioboxChange}
        />
      ))}

      <Input
        id={uuidv4()}
        isDisabled={datePickerStore.isCustomDateInputDisabled}
        label="Укажите дату"
        mask={DATE_MASK}
        maskPlaceholder={DATE_MASK_PLACEHOLDER}
        onBlur={onInputBlur}
        onKeyPress={onInputKeyPress}
        renderCustomMaskText={getDateMask}
        {...datePickerStore.getCustomDateInputProps()}
        {...datePickerStore.getCustomDateValidationProps()}
      />
    </section>
  );
});

export default FilterPanelDates;
