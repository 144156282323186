import { APP_LOCALSTORAGE_NAME } from 'src/constants';

function getAppState() {
  const value = localStorage.getItem(APP_LOCALSTORAGE_NAME);
  if (value) {
    return JSON.parse(value);
  }
  return {};
}

function setAppState(state: any) {
  localStorage.setItem(APP_LOCALSTORAGE_NAME, JSON.stringify(state));
}

export function getLocalStorageItem(key: string) {
  const state = getAppState();
  return state[key] || null;
}

export function setLocalStorageItem(key: string, val: string) {
  const state = getAppState();
  state[key] = val;
  setAppState(state);
}

export function removeLocalStorageItem(key: string) {
  const state = getAppState();
  delete state[key];
  setAppState(state);
}
