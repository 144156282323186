import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/ui/button';
import Input from 'src/components/ui/input';

import AuthStore, { LoginStep } from 'src/stores/auth-store';

const CodeInputForm = observer(({ store }: { store: AuthStore }) => {
  const inputRefs = React.useRef(Array(4).fill(null));

  React.useEffect(() => {
    if (store.activeCodeInputNumber < 4) {
      inputRefs.current[store.activeCodeInputNumber].focus();
    }
  }, [store.activeCodeInputNumber, store.loginStep]);

  React.useEffect(() => () => store.clear(), [store]);

  const getInfoLabel = () => {
    if (store.isCodeFailed && store.codeTimerRemain) {
      return (
        <>
          <div className="login-page__info-row">Неправильный код, попробуйте еще раз</div>
          <div className="login-page__info-row">
            Вы можете запросить код повторно через <span>{`${store.codeTimerRemain} сек.`}</span>
          </div>
        </>
      );
    }

    if (store.isCodeFailed) {
      return 'Неправильный код, попробуйте еще раз';
    }

    if (store.isNewCodeRequested) {
      return (
        <>
          <div className="login-page__info-row">
            Новый код отправлен на номер <span>{store.phone}</span>
          </div>
          <div className="login-page__info-row">
            Вы можете запросить код повторно через <span>{`${store.codeTimerRemain} сек.`}</span>
          </div>
        </>
      );
    }

    if (store.codeTimerRemain) {
      return (
        <>
          <div className="login-page__info-row">
            Код отправлен на номер <span>{store.phone}</span>
          </div>
          <div className="login-page__info-row">
            Вы можете запросить код повторно через <span>{`${store.codeTimerRemain} сек.`}</span>
          </div>
        </>
      );
    }

    return (
      <div className="login-page__info-row">
        Код отправлен на номер <span>{store.phone}</span>
      </div>
    );
  };

  return (
    <>
      <div className="login-page__code-inputs">
        {store.code.map((_, idx) => (
          <Input
            id={uuidv4()}
            key={idx}
            ref={(r) => (inputRefs.current[idx] = r)}
            {...store.getCodeInputsProps(idx)}
            placeholder="_"
            isDisabled={store.loginStep === LoginStep.CODE_CHECKING}
            isOutlined={store.isFormCodeValid}
            isValid={!store.isCodeFailed}
          />
        ))}
      </div>
      <div className="login-page__code-actions">
        <Button
          onClick={store.getNewVerificationCode}
          isDisabled={store.isNewCodeRequestDisabled}
          label="Новый код"
          theme="link"
        />
        <span className="login-page__code-actions-divider" />
        <Button onClick={store.resetPhone} label="Изменить номер телефона" theme="link" />
      </div>
      <div
        className={classNames('login-page__info', {
          'login-page__info_error': store.isCodeFailed,
        })}
      >
        {getInfoLabel()}
      </div>
    </>
  );
});

export default CodeInputForm;
