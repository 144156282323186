import React from 'react';
import { observer } from 'mobx-react-lite';

import SelectWorkersPopup from 'src/components/common/popups/select-workers-popup';

const AllPopups = observer(() => {
  return (
    <>
      <SelectWorkersPopup />
    </>
  );
});

export default AllPopups;
