import React from 'react';

import DateStat from 'src/components/common/date-stat';
import FarewellsFilters from 'src/components/common/farewells-filters';
import OrderSearch from 'src/components/common/order-search';

import controlFiltersStore from 'src/stores/control-room/filters-store';
import sortingFiltersStore from 'src/stores/sorting-center/filters-store';

import { AppRoute } from 'src/constants';
import { TopPanelProps } from './top-panel.props';

import './top-panel.scss';

const getPageData = (pathName: string, crematoriumName: string = '') =>
  ({
    [AppRoute.CONTROL_ROOM_PAGE_ROUTE]: ['Диспетчерская', 'Мониторинг залов прощания'],
    [AppRoute.SORTING_CENTER_PAGE_ROUTE]: ['Сортировочный центр', crematoriumName],
    [AppRoute.MAP_PAGE_ROUTE]: ['Диспетчерская', 'Карта залов прощания'],
  }[pathName] || ['', '']);

function TopPanel({ page }: TopPanelProps) {
  const crematoriumName = 'Честный Агент Окольная'; // TODO: replace mock with dynamic value
  const [title, subtitle] = getPageData(page, crematoriumName);
  const filtersStore =
    page === AppRoute.SORTING_CENTER_PAGE_ROUTE ? sortingFiltersStore : controlFiltersStore;

  const getPlaceholder = () =>
    page === AppRoute.SORTING_CENTER_PAGE_ROUTE ? 'Поиск заказа' : 'Поиск заказа / предзаказа';

  return (
    <div className="top-panel">
      <div className="top-panel__left-wrapper">
        <div className="top-panel__heading">
          <h1 className="top-panel__title">{title}</h1>
          <p className="top-panel__subtitle">{subtitle}</p>
        </div>
        {page === AppRoute.CONTROL_ROOM_PAGE_ROUTE && (
          <FarewellsFilters className="top-panel__farewells-filters" />
        )}
      </div>
      <div className="top-panel__data-filters">
        <DateStat store={filtersStore} page={page} />
        {page === AppRoute.MAP_PAGE_ROUTE || (
          <OrderSearch store={filtersStore} placeholder={getPlaceholder()} />
        )}
      </div>
    </div>
  );
}

export default TopPanel;
