import { getMask } from './get-mask';

export const getDateMask = getMask((value) => (str, index) => {
  const regexp = /\d/;
  let isTextBlack = regexp.test(str);

  if (str === '.' && (regexp.test(value[index + 1]) || regexp.test(value[index - 1]))) {
    isTextBlack = true;
  }

  return { str, isTextBlack };
});
