import React from 'react';

const DEBOUNCE_INTERVAL = 300;

export default function useDebouce(noDebounce: boolean) {
  const [showComponent, setShowComponent] = React.useState(noDebounce);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowComponent(true), DEBOUNCE_INTERVAL);
    return () => clearTimeout(timer);
  }, []);

  return {
    showComponent,
  };
}
