export const PeriodLabel = {
  DAY_AFTER_TOMORROW: 'Послезавтра',
  OTHER_DAY: 'Другой день',
  TODAY: 'Сегодня',
  TOMORROW: 'Завтра',
};

export const FILTERS_PERIODS = [
  PeriodLabel.TODAY,
  PeriodLabel.TOMORROW,
  PeriodLabel.DAY_AFTER_TOMORROW,
  PeriodLabel.OTHER_DAY,
];
