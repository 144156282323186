import React from 'react';
import { observer } from 'mobx-react-lite';

import { Alert } from 'src/components/ui/alert';

import globalAlertStore from 'src/stores/global-alert-store';

import './alert-block.scss';

const AlertBlock = observer(() => {
  const alerts = globalAlertStore.alerts;

  if (!alerts.length) {
    return null;
  }

  return (
    <div className="alert-block scrollbar">
      {alerts.map(({ id, ...rest }) => (
        <Alert key={id} {...rest} onClose={() => globalAlertStore.deleteAlert(id)} />
      ))}
    </div>
  );
});

export default AlertBlock;
