import React from 'react';
import classNames from 'classnames';

import { getListWOrkers } from 'src/utils';
import { ListDocumentsInfoProps } from './list-documents-info.props';

import './list-documents-info.scss';

const ListDocumentsInfo = React.forwardRef<HTMLDivElement, ListDocumentsInfoProps>(
  ({ listCrematoriums }: ListDocumentsInfoProps, ref) => {
    return (
      <div className="list-documents-info" ref={ref}>
        {listCrematoriums.map(({ id, name, workers, cremationsCount }, index, { length }) => {
          const classes = classNames(
            'list-documents-info__text list-documents-info__workers',
            !workers?.length && 'list-documents-info__text_red'
          );

          return (
            <React.Fragment key={id}>
              <div className="list-documents-info__item">
                <span className="list-documents-info__text list-documents-info__text_grey">
                  {name === 'Нижний Новгород' ? 'Н. Новгород' : name}:
                </span>

                <span className="list-documents-info__text list-documents-info__text_semibold">
                  {cremationsCount}
                </span>

                <span className={classes}>–</span>

                <span className={classes}>
                  {workers?.length > 0 ? getListWOrkers(workers) : 'Бригада не выбрана'}
                </span>
              </div>

              {index < length - 1 ? (
                <span className="list-documents__divider list-documents__divider_full-height" />
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
);

export default ListDocumentsInfo;
