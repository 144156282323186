import React from 'react';
import { observer } from 'mobx-react-lite';

import filtersStore from 'src/stores/control-room/filters-store';
import globalAppStore from 'src/stores/global-app-store';

import Map from 'src/components/common/map';

import './map-page.scss';

const MapPage = observer(() => {
  const isConnectionEstablished = globalAppStore.isConnectionEstablished;

  React.useEffect(() => {
    if (isConnectionEstablished) {
      filtersStore.initDate();
    }
  }, [isConnectionEstablished]);

  return (
    <div className="map-page">
      <Map />
    </div>
  );
});

export default MapPage;
