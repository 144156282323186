import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import EmptyData from 'src/components/common/empty-data';
import OrderTable from './components/order-table';
import TooltipTrigger from 'src/components/ui/tooltip-trigger';
import { ReactComponent as EmergencyIcon } from 'src/assets/icons/emergency.svg';
import { ReactComponent as ScarfIcon } from 'src/assets/icons/scarf.svg';

import farewellStore from 'src/stores/control-room/farewell-store';
import filtersStore from 'src/stores/control-room/filters-store';
import globalAppStore from 'src/stores/global-app-store';

import './control-room-page.scss';

const ControlRoomPage = observer(() => {
  const listFarewellHalls = farewellStore.filteredListFarewellHalls;
  const isEmptyData = Object.keys(listFarewellHalls).length === 0;

  const classesPage = classNames('control-room-page', isEmptyData && 'control-room-page_empty');
  const isConnectionEstablished = globalAppStore.isConnectionEstablished;

  React.useEffect(() => {
    if (isConnectionEstablished) {
      filtersStore.initDate();
    }
  }, [isConnectionEstablished]);

  React.useEffect(
    () => () => {
      farewellStore.closeTables();
      filtersStore.clearFilters();
    },
    []
  );

  return (
    <div className={classesPage}>
      <div className="control-room-page__table-wrapper scrollbar">
        <table className="table table_control-room">
          <thead className="table__header">
            <tr className="table__row">
              <th className="table__header-cell table__header-cell_first">Дата</th>
              <th className="table__header-cell">Кремируемый</th>
              <th className="table__header-cell">Агент</th>
              <th className="table__header-cell">Примечания</th>
              <th className="table__header-cell">
                <TooltipTrigger isHideOnScroll={false} text="Срочный">
                  <EmergencyIcon className="control-room-page__icon" />
                </TooltipTrigger>
              </th>
              <th className="table__header-cell table__header-cell_last">Статус</th>
            </tr>
          </thead>
          {!isEmptyData && !farewellStore.isLoading ? (
            <tbody className="table__body">
              {Object.keys(listFarewellHalls)
                .sort()
                .map((farewellHall) => (
                  <OrderTable key={farewellHall} farewellHall={farewellHall} />
                ))}
            </tbody>
          ) : null}
        </table>
      </div>

      {isEmptyData && !farewellStore.isLoading ? (
        <EmptyData
          className="control-room-page__empty-data"
          icon={<ScarfIcon width={32} height={32} />}
          text="Попробуйте изменить параметры фильтрации"
          title="Нет записей"
        />
      ) : null}
    </div>
  );
});

export default ControlRoomPage;
